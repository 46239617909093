/* jshint esversion: 6 */
import Q from "q";
import FullCalendar from "@/page/distributor/component/FullCalendar/pro-detail-calendar.vue";
import web_common_visitor_getHotCity from "@/lib/data-service/default/web_common_visitor_getHotCity";
import web_common_productDetail from "@/lib/data-service/default/web_common_productDetail";
import web_common_isTakeEffect from "@/lib/data-service/default/web_common_isTakeEffect";
import web_PurchaseOrder_shareTheFigure from "@/lib/data-service/default/web_PurchaseOrder_shareTheFigure";
import web_common_findRetailPrice from "@/lib/data-service/default/web_common_findRetailPrice";
import amount_rounded_to_cents from "yinzhilv-js-sdk/src/common-service/amount_rounded_to_cents";
import moment from "moment";
import get_user_info from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/yinzhilv/get_user_info";

export default {
  data() {
    return {
      amount_rounded_to_cents,
      productNo: "",
      detailData: {},
      navActive: 1,
      asideActive: 0,
      imgActive: 0,
      hotCity: [],
      productPicture: [],
      tourismTrip: [],
      bigImg: "",
      wxclub: false,
      autoplay: "",
      notice: {},
      tourismNotice: {},
      tourismTeamResult: [],

      productIndex: 0,

      moment,
      calendarData: {
        specialSource: 0,
        specialStatus: 0,
        events: [],
        locale: "",
        firstDay: 0,
        initialDayTime: 0,
      },

      // userName: 'ruanjianning',
      productId: "",
      adultNumber: 1,
      childNumber: 0,
      childBedNumber: 0,
      agedNumber: 0,
      tourTeamId: "",
      // productNo: 'P77330852052',
      orderNo: 0,
      deportTime: "",

      isShowCodePop: false,

      codeParams: {
        productNo: "",
        productRemark: "",
        amount: "",
        title: "",
        qrCodeUrl: "",
      },
      imgFile: "",
      imgFileName: "",
      canClickCodeBtn: true,

      retailPricePercentage: 0,

      userInfo: {},
    };
  },
  components: {
    FullCalendar,
  },
  created() {
    document.documentElement.scrollTop = 0;
    this.productNo = this.$route.query.productNo;
  },
  async mounted() {
    get_user_info().then(res => {
      this.userInfo = res.userVo
    })
    await this.findRetailPrice();
    this.getProductDetail();
    this.reqGetHotList();
  },
  activated() {},
  deactivated() {},
  destroyed() {
    clearInterval(this.autoplay);
  },
  watch: {
    imgActive: function(val) {
      this.bigImg = this.productPicture[this.imgActive];
      if (this.productPicture.length > 4) {
        const height = 52 * 4 + 4;
        if (val % 4 === 0 || val % 4 === 3) {
          let long = parseInt(val / 4) * height;
          this.$refs.smallItem.style.transform = `translateY(-${long}px)`;
        }
      }
    },
  },
  computed: {
    fromCityName() {
      if (!this.detailData.fromCityName) return;
      const reg = /\$\$/g;
      return this.detailData.fromCityName.replace(reg, "，");
    },
    toCityName: function() {
      if (!this.detailData.toCityName) return;
      const reg = /\$\$/g;
      return this.detailData.toCityName.replace(reg, "，");
    },
    productType() {
      let productType = "";
      switch (this.detailData.productType) {
        case 1:
          productType = "跟团游";
          break;
        case 2:
          productType = "自由行";
          break;
        case 3:
          productType = "当地游";
          break;
        case 4:
          productType = "半自助";
          break;
      }
      return productType;
    },
    hotelLevel() {
      let hotelLevel = "";
      switch (this.detailData.hotelLevel) {
        case 1:
          hotelLevel = "民宿";
          break;
        case 2:
          hotelLevel = "二星级以下";
          break;
        case 3:
          hotelLevel = "准三星级";
          break;
        case 4:
          hotelLevel = "三星级";
          break;
        case 5:
          hotelLevel = "准四星级";
          break;
        case 6:
          hotelLevel = "四星级";
          break;
        case 7:
          hotelLevel = "准五星级";
          break;
        case 8:
          hotelLevel = "五星级";
          break;
        case 9:
          hotelLevel = "四花级";
          break;
        case 10:
          hotelLevel = "五花级";
          break;
        case 11:
          hotelLevel = "当地四星级";
          break;
        case 12:
          hotelLevel = "当地五星级";
          break;
        case 13:
          hotelLevel = "五星级以上";
          break;
        default:
          hotelLevel = "暂无信息";
      }
      return hotelLevel;
    },
    flightType() {
      let flightType = "";
      switch (this.detailData.flightType) {
        case 1:
          flightType = "直飞";
          break;
        case 2:
          flightType = "转机";
          break;
        case 3:
          flightType = "联运";
          break;
        case 4:
          flightType = "经停";
          break;
        case 5:
          flightType = "包机";
          break;
      }
      return flightType;
    },
    flightTime() {
      let flightTime = "";
      switch (this.detailData.flightTime) {
        case 1:
          flightTime = "早班";
          break;
        case 2:
          flightTime = "中班";
          break;
        case 3:
          flightTime = "晚班";
          break;
        case 4:
          flightTime = "夜班";
          break;
      }
      return flightTime;
    },
    trafficMode() {
      let trafficMode = "";
      switch (this.detailData.trafficMode) {
        case 1:
          trafficMode = "航空";
          break;
        case 2:
          trafficMode = "高铁";
          break;
        case 3:
          trafficMode = "动车";
          break;
        case 4:
          trafficMode = "火车";
          break;
        case 5:
          trafficMode = "旅游大巴";
          break;
        case 6:
          trafficMode = "其他";
          break;
        default:
          trafficMode = "暂无信息";
      }
      return trafficMode;
    },
    // 总价
    totalPrice() {
      let index = this.productIndex;
      if (this.tourismTeamResult.length === 0) {
        return "0.00";
      }
      let value =
          this.tourismTeamResult[index].balanceAdultPrice * this.adultNumber +
          this.tourismTeamResult[index].balanceChildPrice * this.childNumber +
          this.tourismTeamResult[index].balanceChildBedPrice *
          this.childBedNumber +
          this.tourismTeamResult[index].balanceAgedPrice * this.agedNumber;
      let price = this.returnFloat(value);

      return price;
    },
    // 建议零售价
    retailPrice() {
      let index = this.productIndex;
      let num = 0;
      if (this.detailData.tourismTeamResult.length === 0) {
        return 0
      }
      let adultRetailPrice = this.detailData.tourismTeamResult[index].adultRetailPrice; // 初始建议价
      let balanceAdultPrice = this.detailData.tourismTeamResult[index].balanceAdultPrice; // 初始结算价
      if (adultRetailPrice !== 0) {
        while (adultRetailPrice <= balanceAdultPrice) {
          num++;
          adultRetailPrice = adultRetailPrice * (1 + this.retailPricePercentage);
        }
      }
      if (num > 0) {
        let retail = this.detailData.tourismTeamResult[index].adultRetailPrice;
        for (let i = 0, l = num; i < l; i++) {
          retail = retail * (1 + this.retailPricePercentage);
        }
        return retail;
      } else {
        return this.detailData.tourismTeamResult[index].adultRetailPrice;
      }
    },
    adultDisabled() {
      let index = this.productIndex;
      let tourismTeamResult = this.tourismTeamResult;
      if (
          tourismTeamResult === null ||
          tourismTeamResult === undefined ||
          tourismTeamResult.length === 0
      ) {
        return true;
      } else if (
          tourismTeamResult[index].balanceAdultPrice === 0 ||
          tourismTeamResult[index].balanceAdultPrice === null
      ) {
        return true;
      } else {
        return false;
      }
    },
    childBedDisabled() {
      let index = this.productIndex;
      let tourismTeamResult = this.tourismTeamResult;
      if (
          tourismTeamResult === null ||
          tourismTeamResult === undefined ||
          tourismTeamResult.length === 0
      ) {
        return true;
      } else if (
          tourismTeamResult[index].balanceChildBedPrice === 0 ||
          tourismTeamResult[index].balanceChildBedPrice === null
      ) {
        return true;
      } else {
        return false;
      }
    },
    childDisabled() {
      let index = this.productIndex;
      let tourismTeamResult = this.tourismTeamResult;
      if (
          tourismTeamResult === null ||
          tourismTeamResult === undefined ||
          tourismTeamResult.length === 0
      ) {
        return true;
      } else if (
          tourismTeamResult[index].balanceChildPrice === 0 ||
          tourismTeamResult[index].balanceChildPrice === null
      ) {
        return true;
      } else {
        return false;
      }
    },
    agedDisabled() {
      let index = this.productIndex;
      let tourismTeamResult = this.tourismTeamResult;
      if (
          tourismTeamResult === null ||
          tourismTeamResult === undefined ||
          tourismTeamResult.length === 0
      ) {
        return true;
      } else if (
          tourismTeamResult[index].balanceAgedPrice === 0 ||
          tourismTeamResult[index].balanceAgedPrice === null
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  filters: {
    returnFloatFilter(item) {
      let value = Math.round(parseFloat(item) * 100) / 100;
      let xsd = value.toString().split(".");
      if (xsd.length === 1) {
        value = value.toString() + ".00";
        return value;
      }
      if (xsd.length > 1) {
        if (xsd[1].length < 2) {
          value = value.toString() + "0";
        }
        return value;
      }
    },
  },
  methods: {
    returnFloat(item) {
      let value = Math.round(parseFloat(item) * 100) / 100;
      let xsd = value.toString().split(".");
      if (xsd.length === 1) {
        value = value.toString() + ".00";
        return value;
      }
      if (xsd.length > 1) {
        if (xsd[1].length < 2) {
          value = value.toString() + "0";
        }
        return value;
      }
    },
    reqGetHotList() {
      Q.when()
          .then(() => {
            return web_common_visitor_getHotCity();
          })
          .then((res) => {
            this.hotCity = res.hotCitys;
          });
    },
    getProductDetail() {
      let params = {
        productNo: this.$route.query.productNo,
      };
      web_common_productDetail(params).then((res) => {
        this.detailData = res;
        this.tourismNotice = res.tourismNotice;
        this.tourismTrip = res.tourismTrip;
        this.productPicture = res.productPicture.split("$$");
        this.bigImg = this.productPicture[0];
        this.productId = this.detailData.id;
        let tourismTeamResult = this.detailData.tourismTeamResult;

        switch (this.detailData.productLevel) {
          case 1:
            this.detailData.productLevelText = "经典";
            break;
          case 2:
            this.detailData.productLevelText = "经济";
            break;
          case 3:
            this.detailData.productLevelText = "促销";
            break;
          case 4:
            this.detailData.productLevelText = "尊享";
            break;
          case 6:
            this.detailData.productLevelText = "臻品";
            break;
          default:
            this.detailData.productLevelText = "";
        }
        let isFirst = true;
        tourismTeamResult.forEach((value, index) => {
          value.dayValue = moment(value.teamTime, "YYYY-MM-DD hh:mm:ss").format(
              "YYYY-MM-DD"
          );
          if (
              (value.inventoryType === 3 ||
                  value.inventoryType === 2 ||
                  (value.inventoryType === 1 && value.emptySeat > 0)) &&
              isFirst
          ) {
            this.tourTeamId = value.id;
            this.deportTime = value.dayValue;
            this.productIndex = index;
            isFirst = false;
            /*let emptySeat = value.specialStatus === 0 ? value.specialEmptySeat : value.emptySeat
            if (emptySeat >= 1) {
              this.adultNumber = 1
            } else {
              this.adultNumber = 0
            } */
          }
          const day = moment(value.teamTime, "YYYY-MM-DD hh:mm:ss").format(
              "MM-DD"
          );
          const week = moment(value.teamTime, "YYYY-MM-DD hh:mm:ss").weekday();
          let weekFont = "";
          switch (week) {
            case 1:
              weekFont = "星期一";
              break;
            case 2:
              weekFont = "星期二";
              break;
            case 3:
              weekFont = "星期三";
              break;
            case 4:
              weekFont = "星期四";
              break;
            case 5:
              weekFont = "星期五";
              break;
            case 6:
              weekFont = "星期六";
              break;
            default:
              weekFont = "星期天";
          }
          value.label = `${day}${weekFont}出发,${value.balanceAdultPrice}元/成人价，${value.balanceChildBedPrice}元/儿童占床价，${value.balanceChildPrice}元/儿童不占床价，${value.balanceAgedPrice}元/老人价`;
        });
        this.tourismTeamResult = tourismTeamResult;
        this.calendarData.specialSource = this.detailData.specialSource;
        this.calendarData.specialStatus = this.detailData.specialStatus;
        this.calendarData.events = this.detailData.tourismTeamResult;
        // tourismTeamResult
        this.autoplayImg();
        this.scrollChange();
      });
    },
    changeTourTeamId(val) {
      const arrLength = this.tourismTeamResult.length;
      for (let i = 0; i < arrLength; i++) {
        if (this.tourismTeamResult[i].id === val) {
          this.productIndex = i;
          this.deportTime = moment(
              this.tourismTeamResult[i].teamTime,
              "YYYY-MM-DD hh:mm:ss"
          ).format("YYYY-MM-DD");
          break;
        }
      }
    },
    toHome() {
      this.$router.push({
        name: "distributor-tour-front-home",
      });
      document.documentElement.scrollTop = 0;
    },
    toSearch(query) {
      this.$router.push({
        name: "distributor-tour-front-search",
        query,
      });
      document.documentElement.scrollTop = 0;
    },
    changeActive(index, id) {
      this.navActive = index;
      document.querySelector(id).scrollIntoView(true);
    },
    mouseImg(index) {
      this.imgActive = index;
      // this.bigImg = this.productPicture[index]
    },
    imgUp() {
      if (this.imgActive === 0) {
        this.imgActive = this.productPicture.length - 1;
      } else {
        this.imgActive -= 1;
      }
    },
    imgDown() {
      if (this.imgActive === this.productPicture.length - 1) {
        this.imgActive = 0;
      } else {
        this.imgActive += 1;
      }
    },
    autoplayImg() {
      if (this.productPicture.length === 0) {
        return;
      }
      this.autoplay = setInterval(() => {
        this.imgActive += 1;
        if (this.imgActive >= this.productPicture.length) {
          this.imgActive = 0;
        }
      }, 3000);
    },
    stopAutoPlay() {
      clearInterval(this.autoplay);
    },
    asideChange(index, key) {
      let id = `${key}${index}`;
      document.querySelector(id).scrollIntoView(true);
      this.asideActive = index;
    },
    wheelChange() {
      if (!this.$refs.detailNavScroll) {
        window.onscroll = null;
        return;
      }
      const _this = this;

      let asideList = [];

      for (let i = 0; i < this.tourismTrip.length; i++) {
        asideList[i] = `aside${i}`;
      }
      asideList.forEach(function(item, index) {
        let element = _this.$refs[item];
        let top = element[0].getBoundingClientRect().top;
        if (top <= 52) {
          _this.asideActive = index;
        }
      });

      const detailsBottom = _this.$refs.detailsMain.getBoundingClientRect()
          .bottom;

      //const journeyTop = _this.$refs.journey.getBoundingClientRect().top;
      const featureTop = _this.$refs.feature.getBoundingClientRect().top;
      const expenseTop = _this.$refs.expense.getBoundingClientRect().top;
      const reserveTop = _this.$refs.reserve.getBoundingClientRect().top;
      if (reserveTop <= 52) {
        this.navActive = 4;
      } else if (expenseTop <= 52) {
        this.navActive = 3;
      } else if (featureTop <= 52) {
        this.navActive = 2;
      } else {
        this.navActive = 1;
      }

      // 推荐
      // 外层
      const hotWrapTop = _this.$refs.hotListScroll.getBoundingClientRect().top;
      const hotWrapLeft = _this.$refs.hotListScroll.getBoundingClientRect()
          .left;
      // 内层
      const hotInnerHeight = _this.$refs.hotListInnerScroll.offsetHeight;

      if (hotWrapTop <= 70) {
        if (detailsBottom <= hotInnerHeight) {
          _this.$refs.hotListScroll.style.position = "absolute";
          _this.$refs.hotListScroll.style.bottom = `0px`;
          _this.$refs.hotListScroll.style.right = `0px`;
        } else {
          _this.$refs.hotListInnerScroll.style.position = "fixed";
          _this.$refs.hotListInnerScroll.style.top = `70px`;
          _this.$refs.hotListInnerScroll.style.left = `${hotWrapLeft}px`;
        }
      } else {
        _this.$refs.hotListInnerScroll.style.position = "absolute";
        _this.$refs.hotListInnerScroll.style.left = `0px`;
        _this.$refs.hotListInnerScroll.style.top = `0px`;
      }

      // 导航
      // 外层
      const detailWrapTop = _this.$refs.detailNavScroll.getBoundingClientRect()
          .top;
      const detailWrapLeft = _this.$refs.detailNavScroll.getBoundingClientRect()
          .left;
      if (detailWrapTop <= 0) {
        _this.$refs.detailNavInnerScroll.style.position = "fixed";
        _this.$refs.detailNavInnerScroll.style.top = `0px`;
        _this.$refs.detailNavInnerScroll.style.left = `${detailWrapLeft}px`;
      } else {
        _this.$refs.detailNavInnerScroll.style.position = "absolute";
        _this.$refs.detailNavInnerScroll.style.left = `0px`;
        _this.$refs.detailNavInnerScroll.style.top = `0px`;
      }

      // 天数
      const detailsListWrapBottom = _this.$refs.detailsListWrap.getBoundingClientRect()
          .bottom;
      // 外层
      const listWrapTop = _this.$refs.asideScroll.getBoundingClientRect().top;
      const listWrapLeft = _this.$refs.asideScroll.getBoundingClientRect().left;
      // 内层
      const listInnerHeight = _this.$refs.asideInnerScroll.offsetHeight;
      const asideInnerBottom = _this.$refs.asideInnerScroll.getBoundingClientRect()
          .bottom;
      const journeyItemBottom = _this.$refs.journeyItem.getBoundingClientRect()
          .bottom;
      if (asideInnerBottom >= journeyItemBottom || this.navActive !== 1) {
        _this.$refs.asideInnerScroll.style.visibility = "hidden";
      } else {
        _this.$refs.asideInnerScroll.style.visibility = "visible";
      }

      if (listWrapTop <= 70) {
        if (detailsListWrapBottom <= listInnerHeight) {
          _this.$refs.asideInnerScroll.style.position = "fixed";
          _this.$refs.asideInnerScroll.style.bottom = `${listInnerHeight}px`;
          _this.$refs.asideInnerScroll.style.left = `${listWrapLeft}px`;
        } else {
          _this.$refs.asideInnerScroll.style.position = "fixed";
          _this.$refs.asideInnerScroll.style.top = `75px`;
          _this.$refs.asideInnerScroll.style.left = `${listWrapLeft}px`;
        }
      } else {
        _this.$refs.asideInnerScroll.style.position = "absolute";
        _this.$refs.asideInnerScroll.style.left = `0px`;
        _this.$refs.asideInnerScroll.style.top = `0px`;
      }
    },
    scrollChange() {
      window.onscroll = this.wheelChange;
    },
    toReserve(val) {
      if (this.detailData.productType === 1 && this.userInfo.userType === 7) {
        return
      }
      // 先判断余位
      let hasSeat = true;
      let emptySeat;
      if (this.tourismTeamResult[this.productIndex].inventoryType === 1) {
        emptySeat = this.tourismTeamResult[this.productIndex].specialStatus === 0 ? this.tourismTeamResult[this.productIndex].specialEmptySeat : this.tourismTeamResult[this.productIndex].emptySeat;
        if (emptySeat === 0) {
          hasSeat = false;
        }
      }

      if (!hasSeat) {
        this.$message({
          type: "warning",
          message: "该团期没有余位！",
        });
        return;
      }
      let totalNumber = this.adultNumber + this.childNumber + this.childBedNumber + this.agedNumber;
      if (totalNumber === 0) {
        this.$message({
          type: "warning",
          message: "请输入人数！",
        });
        return;
      }
      if (
          this.tourismTeamResult[this.productIndex].inventoryType === 1 && totalNumber > emptySeat
      ) {
        this.$message({
          type: "warning",
          message: "人数超过团期余位！",
        });
        return;
      }
      let params = {
        productNo: val,
        productId: this.productId,
        departTime: this.tourismTeamResult[this.productIndex].dayValue,
        total:
            this.adultNumber +
            this.childNumber +
            this.childBedNumber +
            this.agedNumber,
      };
      web_common_isTakeEffect(params).then((res) => {
        // 成功
        this.$router.push({
          name: "distributor-tour-front-reserve",
          query: {
            productNo: val,
            adultNumber: this.adultNumber,
            childNumber: this.childNumber,
            childBedNumber: this.childBedNumber,
            agedNumber: this.agedNumber,
            tourTeamId: this.tourismTeamResult[this.productIndex].id,
          },
        });
      });
    },

    dayClick(val, jsEvent) {
      const arrLength = this.tourismTeamResult.length;
      for (let i = 0; i < arrLength; i++) {
        if (this.tourismTeamResult[i].dayValue === val.events.date) {
          this.productIndex = i;
          this.deportTime = val.events.date;
          this.tourTeamId = this.tourismTeamResult[i].id;
          break;
        }
      }
    },

    handleChangeAdult(val, oldVal) {
      let total = val + this.childBedNumber + this.childNumber + this.agedNumber;
      console.log(this.tourismTeamResult[this.productIndex]);
      if (this.tourismTeamResult[this.productIndex].inventoryType === 1 && total > this.tourismTeamResult[this.productIndex].emptySeat) {
        this.$message({
          type: "warning",
          message: "人数不能超过余位",
        });
        this.$nextTick(() => {
          this.adultNumber = oldVal;
        });
      }
    },
    handleChangeChildBed(val, oldVal) {
      let total = val + this.adultNumber + this.childNumber + this.agedNumber;
      if (
          this.tourismTeamResult[this.productIndex].inventoryType === 1 &&
          total > this.tourismTeamResult[this.productIndex].emptySeat
      ) {
        this.$message({
          type: "warning",
          message: "人数不能超过余位",
        });
        this.$nextTick(() => {
          this.childBedNumber = oldVal;
        });
      }
    },
    handleChangeChild(val, oldVal) {
      let total =
          val + this.adultNumber + this.childBedNumber + this.agedNumber;
      if (
          this.tourismTeamResult[this.productIndex].inventoryType === 1 &&
          total > this.tourismTeamResult[this.productIndex].emptySeat
      ) {
        this.$message({
          type: "warning",
          message: "人数不能超过余位",
        });
        this.$nextTick(() => {
          this.childNumber = oldVal;
        });
      }
    },
    handleChangeAged(val, oldVal) {
      let total =
          val + this.adultNumber + this.childBedNumber + this.childNumber;
      if (
          this.tourismTeamResult[this.productIndex].inventoryType === 1 &&
          total > this.tourismTeamResult[this.productIndex].emptySeat
      ) {
        this.$message({
          type: "warning",
          message: "人数不能超过余位",
        });
        this.$nextTick(() => {
          this.agedNumber = oldVal;
        });
      }
    },

    showCodePop() {
      this.isShowCodePop = true;
    },
    closeCodePop() {
      this.isShowCodePop = false;
    },
    createCodePic() {
      if (!this.canClickCodeBtn) {
        return;
      }
      if (this.codeParams.title.length === 0) {
        this.$message({
          type: "warning",
          message: "请输入标题",
        });
        return;
      }
      if (this.codeParams.title.length > 13) {
        this.$message({
          type: "warning",
          message: "标题不能超过13字",
        });
        return;
      }
      if (this.codeParams.productRemark.length === 0) {
        this.$message({
          type: "warning",
          message: "内容不能为空",
        });
        return;
      }
      if (this.codeParams.productRemark.length > 100) {
        this.$message({
          type: "warning",
          message: "内容不能超过100字",
        });
        return;
      }
      if (this.codeParams.amount === "" || this.codeParams.amount === 0) {
        this.$message({
          type: "warning",
          message: "请输入门市价",
        });
        return;
      }
      this.canClickCodeBtn = false;
      this.codeParams.productNo = this.productNo;
      this.codeParams.qrCodeUrl = this.detailData.qrCodeUrl;
      web_PurchaseOrder_shareTheFigure(this.codeParams)
          .then((res) => {
            this.canClickCodeBtn = true;
            if (res.sharePictureUrl === "") {
              return;
            }

            this.imgFile = res.sharePictureUrl;
            this.$nextTick(() => {
              this.$refs.downloadBtn.click();
            });
            // this.downloadByBlob(res.sharePictureUrl, this.codeParams.title)
            this.isShowCodePop = false;
            this.$message({
              type: "success",
              message: "生成截图成功",
            });

            /*return;
          this.imgFile = res.sharePictureUrl
          this.$nextTick(() => {
            this.$refs.downloadBtn.click()
          })*/
          })
          .catch(() => {
            this.canClickCodeBtn = true;
          });
    },
    downloadByBlob(url, name) {
      let image = new Image();
      image.setAttribute("crossOrigin", "anonymous");
      image.src = url;
      image.onload = () => {
        let canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        let ctx = canvas.getContext("2d");
        ctx.drawImage(image, 0, 0, image.width, image.height);
        canvas.toBlob((blob) => {
          let url = URL.createObjectURL(blob);
          let eleLink = document.createElement("a");
          eleLink.download = name;
          eleLink.href = url;
          eleLink.click();
          eleLink.remove();
          // 用完释放URL对象
          URL.revokeObjectURL(url);
        });
      };
    },
    // 去切位
    goCut() {
      if (this.detailData.isShowCut === 1 && (this.detailData.productType !== 1 || (this.detailData.productType === 1 && this.userInfo.userType !== 7))) {
        this.$router.push({
          path: "/distributor/tour/front/travel/order",
          query: {
            productNo: this.productNo,
          },
        });
      }
    },
    // 获取价位
    async findRetailPrice() {
      let res = await web_common_findRetailPrice();
      this.retailPricePercentage = parseInt(res.systemConfig.configValue) / 100;
    },
  },
};
