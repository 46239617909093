//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import dateFunc from './components/dateFunc';
import moment from 'moment';
import EventCard from './components/eventCard.vue';
import vHeader from './components/header.vue';

export default {
  props: {
    specialSource: { // 产品的特价来源
      type: Number,
      default: 0
    },
    specialStatus: { // 产品的特价状态
      type: Number,
      default: 0
    },
    events: {
      type: Array,
      default: () => []
    },
    locale: {
      type: String,
      default: 'en'
    },
    firstDay: {
      type: Number | String,
      validator(val) {
        let res = parseInt(val);
        return res >= 0 && res <= 7;
      },
      default: 0
    },
    initialDayTime: {
      type: Number,
      default: 0
    },
  },
  components: {
    'event-card': EventCard,
    'fc-header': vHeader
  },
  watch: {
    initialDayTime(val) {
      for (let time in this.formTime) {
        this.formTime[time] = this.eventBox;
      }
    }
  },

  mounted () {
    this.currentMonth = moment(this.events[0].dayValue);
  },

  data() {
    return {
      currentMonth: moment().startOf('month'),
      isLismit: true,
      eventLimit: 3,
      eventBox: [],
      formTime: {},
      timeKey: '',
      showMore: false,
      showList: false,
      morePos: {
        top: 0,
        left: 0
      },
      selectDay: {},
      btnStyle: true
    };
  },
  computed: {
    currentDates() {
      return this.getCalendar();
    }
  },

  methods: {
    emitChangeMonth(firstDayOfMonth) {
      let nowDate = new Date(moment().format('YYYY-MM-01 00:00:00')).valueOf();
      let clickMonth = new Date(firstDayOfMonth).valueOf();
      if (clickMonth >= nowDate) {
        this.btnStyle = false;
        this.currentMonth = firstDayOfMonth;
        let start = dateFunc.getMonthViewStartDate(
          firstDayOfMonth,
          this.firstDay
        );
        let end = dateFunc.getMonthViewEndDate(firstDayOfMonth, this.firstDay);
        this.$emit('changeMonth', start, end, firstDayOfMonth);
      }
    },
    getCalendar() {
      // calculate 2d-array of each month
      let monthViewStartDate = dateFunc.getMonthViewStartDate(
        this.currentMonth,
        this.firstDay
      );
      let calendar = [];
      for (let perWeek = 0; perWeek < 6; perWeek++) {
        let week = [];

        for (let perDay = 0; perDay < 7; perDay++) {
          week.push({
            monthDay: monthViewStartDate.date(),
            isToday: monthViewStartDate.isSame(moment(), 'day'),
            isCurMonth: monthViewStartDate.isSame(this.currentMonth, 'month'),
            weekDay: perDay,
            date: moment(monthViewStartDate),
            events: this.filterGroup(this.events, monthViewStartDate),
          });
          monthViewStartDate.add(1, 'day');
        }
        calendar.push(week);
      }
      return calendar;
    },
    filterGroup(list, date) {
      let events = {};
      list.map(item => {
        if (
          moment(item.teamTime).format('YYYY-MM-DD') === date.format('YYYY-MM-DD')
        ) {
          events = {
            emptySeat: item.specialStatus === 0 ? item.specialEmptySeat : item.emptySeat,
            inventoryType: item.inventoryType, // 1.库存  2.不限量  3.现询
            price: item.balanceAdultPrice,
            holdSeat: item.holdSeat,
            date: moment(item.teamTime).format('YYYY-MM-DD'),
            peopleNum: 1,
            totalAll: 0,
            specialStatus: item.specialStatus // 特价出售状态:0为起售,1为停售
          };
        }
      });
      return events;
    },
    computePos(target) {
      let eventRect = target.getBoundingClientRect();
      let pageRect = this.$refs.dates.getBoundingClientRect();
      return {
        left: eventRect.left - pageRect.left,
        top: eventRect.top + eventRect.height - pageRect.top
      };
    },
    dayClick(day, jsEvent, data, index, zIndex, monthShow) {
      console.log(day, jsEvent, data, index, zIndex, monthShow)
      if (Object.keys(data.events).length === 0 || (data.events.inventoryType === 1 && data.events.emptySeat <= 0)) return;
      this.$emit('dayClick', data, jsEvent);
    },
    eventClick(event, jsEvent) {
      if (!event.isShow) return;
      jsEvent.stopPropagation();
      let pos = this.computePos(jsEvent.target);
      this.$emit('eventClick', event, jsEvent, pos);
    }
  },
  filters: {
    localeWeekDay(weekday, firstDay, locale) {
      firstDay = parseInt(firstDay);
      const localMoment = moment().locale(locale);
      let week = localMoment
        .localeData()
        .weekdaysShort()
        [(weekday + firstDay) % 7].substring(1);
      return week;
    }
  }
};
